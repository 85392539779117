<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center" v-if="this.type != 104">
          {{ courses.title }}
        </h2>

        <div class="acontent">上课老师： {{ courses.teacherUserName }}</div>
        <div class="acontent">上课地点： {{ courses.address }}</div>
        <div class="acontent">上课时间： {{ courses.startTime }}</div>
        <div class="acontent">课程时长： {{ courses.courseLength }} 小时</div>
        <div class="acontent">课程简介： {{ courses.summary }}</div>
      </div>

      <van-tabs v-model="activeName" sticky>
        <van-tab title="签到二维码" name="a" v-if="courses.hasQrRole">
          <div style="text-align: center">
            <van-image :src="courses.qrCodeImage" />
            <h2 style="text-align: center">签到码： {{ courses.title }}</h2>
            <h3 style="text-align: center">
              {{   teacherNameFix(courses.teacherUserName)  }}
            </h3>
          </div>
        </van-tab>
        <van-tab title="评价二维码" name="c" v-if="courses.hasQrRole">
          <div style="text-align: center">
            <van-image :src="courses.commentQRCodeImage" />
            <h2 style="text-align: center">评价码： {{ courses.title }}</h2>
            <h3 style="text-align: center">
              {{ teacherNameFix(courses.teacherUserName)  }}
            </h3>
          </div>
        </van-tab>
        <van-tab title="已签到" name="b">
          <div class="tabcontent">
            <Browsinglog :filterData="BrowsinglogfilterData"></Browsinglog>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getCourseOffline } from "@/api/courseoffline";
import Browsinglog from "../components/Browsinglog";

export default {
  name: "znarticledetail",
  data() {
    return {
      activeName: "a",
      loading: true,
      type: 100,
      title: "",
      courses: {},
      BrowsinglogfilterData: {
        itemId: "",
        itemType: 9,
      },
    };
  },
  components: {
    Browsinglog,
  },
  updated() {
    this.addpreview();
  },
  created() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/user/mycourseofflinedetail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    toProduct(item) {
      this.$router.push({
        path: "/article",
        query: { type: item.typeId, productId: item.productId },
      });
    },
    teacherNameFix(tname){
      if(tname){
        if(tname.indexOf('老师')>=0){
          return tname;
        }
        if(tname.indexOf('(')>=0){
          return tname.replace("(","老师(");
        }
        
          return tname+'老师';
        
      }
      else{
        return "";
      }

    },
    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getCourseOffline(para); //({ ...this.page })
      this.courses = aresult.data.data;
      if (!this.courses.hasQrRole) {
        this.activeName = "b";
      }

      this.BrowsinglogfilterData.itemId = this.courses.id;
      this.title = "线下课程二维码";

      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.courses.id);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  padding: 5px 5px 5px 5px;
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>